import React, { useEffect, useRef } from 'react';

const TeamSection = () => {
    const teamMembers = [
        {
            name: "Anjim Ali",
            role: "Founder & CEO",
            image: "img/sirAnjum.jpg",
        },
        {
            name: "Asad Rehman",
            role: "Executive Manager",
            image: "img/team-5.png",
        },
        {
            name: "Adeel Afzal",
            role: "Co Founder",
            image: "img/New Project.jpg",
        },
        {
            name: "Moiz Amir",
            role: "Project Manager",
            image: "img/successful-businessman_1098-18155.jpg",
        },
    ];

    const teamRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (teamRef.current) {
                const offsetTop = teamRef.current.offsetTop;
                const scrollPosition = window.scrollY + window.innerHeight;

                if (scrollPosition > offsetTop) {
                    teamRef.current.classList.add('fade-in');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div ref={teamRef} className="container-fluid bg-light py-5">
            <div className="container py-5">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5">
                        <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Team</div>
                        <h1 className="mb-4">Meet Our Experienced Team Members</h1>
                        <p className="mb-4">At Cyber Nexus Tech, innovation is our ethos. Meet our dynamic team of experts driving software solutions, app and web development, and pioneering free education and science initiatives. Passionate and dedicated, we're committed to shaping a tech-driven future for all.</p>
                        <a className="btn btn-primary rounded-pill px-4" href="/">Read More</a>
                    </div>
                    <div className="col-lg-7">
                        <div className="row g-4">
                            {teamMembers.map((member, index) => (
                                <div key={index} className="col-md-6">
                                    <div className={`team-item bg-white text-center rounded p-4 pt-0 fade-in`} data-wow-delay={`${index * 0.2}s`}>
                                        <img className="img-fluid rounded-circle p-4" src={member.image} alt={member.name} />
                                        <h5 className="mb-0">{member.name}</h5>
                                        <small>{member.role}</small>
                                        <div className="d-flex justify-content-center mt-3">
                                            <a className="btn btn-square btn-primary m-1" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-instagram"></i></a>
                                            <a className="btn btn-square btn-primary m-1" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-linkedin-in"></i></a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamSection;
