import React from 'react';
import { Link } from 'react-router-dom';

const ProjectHero = () => {
  return (
    <div className="container-fluid pt-5 bg-light hero-header">
      <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <h1 className="display-4 text-black mb-4 animated slideInRight">Our Projects</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center justify-content-lg-start mb-0">
                <li className="breadcrumb-item"><Link to="/" className="text-black">Home</Link></li>
                <li className="breadcrumb-item text-black active" aria-current="page"> / <Link to="/projectsPage" className="text-black">Our Projects</Link></li>
              </ol>
            </nav>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end">
            <img className="img-fluid" src="img/hero-img.png" alt="" style={{ maxHeight: '300px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHero;
