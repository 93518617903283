import React, { useEffect, useRef } from 'react';
import './CaseStudySection.css'; // Import the CSS file for styling

const CaseStudySection = () => {
  const sectionRef = useRef();

  useEffect(() => {
    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const sectionTop = sectionElement.offsetTop;

      if (scrollPosition > sectionTop) {
        sectionElement.classList.add('fadeIn');
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container-fluid bg-light py-5 case-study-section" ref={sectionRef}>
      <div className="container py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
          <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Case Study</div>
          <h1 className="mb-4">Explore Our Recent Projects</h1>
        </div>
        <div className="row g-4">
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
            <div className="case-item position-relative overflow-hidden rounded mb-2">
              <img className="img-fluid" src="img/lmsBack.jpg" alt="" />
              <a className="case-overlay text-decoration-none" href="http://cyberskillsphere.tech/" target="_blank">
                <small>learning Management System</small>
                <h5 className="lh-base text-white mb-3">Cyber Nexus Lms System for free education
                </h5>
                <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
              </a>
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
            <div className="case-item position-relative overflow-hidden rounded mb-2">
              <img className="img-fluid" src="img/posBack.jpg" alt="" />
              <a className="case-overlay text-decoration-none" href="https://loyverse.com/" target="_blank" >
                <small>P.O.S System</small>
                <h5 className="lh-base text-white mb-3">P.O.S System by Cyber Nexus Tech for prople who dont want to spend money to buy POS systems
                </h5>
                <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
              </a>
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
            <div className="case-item position-relative overflow-hidden rounded mb-2">
              <img className="img-fluid" src="img/ecomreceBack.jpg" alt="" />
              <a className="case-overlay text-decoration-none"  href="https://themewagon.github.io/eshopper/" target="_blank">
                <small>E Shopper</small>
                <h5 className="lh-base text-white mb-3"> A proud product of Cyber Nexus Tech for an online store
                </h5>
                <span className="btn btn-square btn-primary"><i className="fa fa-arrow-right"></i></span>
              </a>
            </div>
          </div>
 
        </div>
      </div>
    </div>
  );
};

export default CaseStudySection;
