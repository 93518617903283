import React, { useState, useEffect, useRef } from 'react';
import './Testimonial.css'; // Import your CSS file for styling

const Testimonial = () => {
  const testimonials = [
     
      {
        text: "Working with Cyber Nexus Tech was a game-changer. Their attention to detail and innovative solutions exceeded our expectations.",
        imgSrc: "img/testimonial-1.jpg",
        name: "Jane Smith",
        profession: "CEO, Tech Innovations Inc."
      },
      {
        text: "Cyber Nexus Tech delivers exceptional results. Their expertise in app development and dedication to client satisfaction is commendable.",
        imgSrc: "img/testimonial-2.jpg",
        name: "John Doe",
        profession: "Founder, Digital Ventures"
      },
      {
        text: "I am impressed with Cyber Nexus Tech's commitment to free education initiatives. Their contributions are shaping a brighter future for all.",
        imgSrc: "img/testimonial-3.jpg",
        name: "Alex Johnson",
        profession: "Educator"
      } 
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const sectionRef = useRef(null);

  const handleNext = () => {
    setCurrentTestimonial((currentTestimonial + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentTestimonial((currentTestimonial - 1 + testimonials.length) % testimonials.length);
  };

  useEffect(() => {
    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const sectionTop = sectionElement.offsetTop;

      if (scrollPosition > sectionTop) {
        sectionElement.classList.add('fadeIn');
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionRef]);

  return (
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-5">
            <div ref={sectionRef} className="testimonial-section">
              <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Testimonial</div>
              <h1 className="mb-4">What Say Our Clients!</h1>
              <p className="mb-4">Explore the client experience at Cyber Nexus Tech! Our commitment to excellence resonates through testimonials. Tempor erat elitr rebum at clita. Diam dolor ipsum et tempor sit. Aliqu diam amet, stet no labore lorem sit. Sanctus clita duo justo et tempor eirmod magna dolore erat amet</p>
              <a className="btn btn-primary rounded-pill px-4" href="#">Read More</a>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="testimonial-carousel-container border-start border-primary">
              <div className="testimonial-item ps-5">
                <i className="fa fa-quote-left fa-2x text-primary mb-3"></i>
                <p className="fs-4">{testimonials[currentTestimonial].text}</p>
                <div className="d-flex align-items-center">
                  <img className="img-fluid flex-shrink-0 rounded-circle" src={testimonials[currentTestimonial].imgSrc} alt="Client Avatar" style={{ width: '60px', height: '60px' }} />
                  <div className="ps-3">
                    <h5 className="mb-1">{testimonials[currentTestimonial].name}</h5>
                    <span>{testimonials[currentTestimonial].profession}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-buttons">
              <button style={{ marginTop: '75px' }} className="arrow-button btn btn-primary rounded-pill px-4" onClick={handlePrev}>Previous</button>
              <button style={{ marginTop: '75px', marginLeft: '25px' }} className="arrow-button btn btn-primary rounded-pill px-4" onClick={handleNext}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
