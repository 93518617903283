import React from 'react';
import NavBar from './NavBar';
import ProjectHero from './ProjectHero';
import CaseStudySection from './CaseStudySection';
import FAQSection from './FAQSection';
import Newsletter from './Newsletter';
import Footer from './Footer';

const ProjectsPage = () => {
  return (
    <div>
          <NavBar/>  {/* Include the NavBar component */}
          <ProjectHero/>  {/* Include the NavBar component */} 
          <CaseStudySection/>  {/* Include the NavBar component */} 
          <FAQSection/>  {/* Include the NavBar component */}
          <Newsletter/>  {/* Include the NavBar component */}
          <Footer/>  {/* Include the NavBar component */}
    </div>
  );
};

export default ProjectsPage;
