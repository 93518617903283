import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './ServiceSection.css'; // Import the CSS file for styling

const ServiceSection = () => {
  const sectionRef = useRef();

  useEffect(() => {
    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const sectionTop = sectionElement.offsetTop;

      if (scrollPosition > sectionTop) {
        sectionElement.classList.add('fadeIn');
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container-fluid bg-light mt-5 py-5 service-section" ref={sectionRef}>
      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Our Services</div>
            <h1 className="mb-4">Our Excellent IT Solutions for Your Business</h1>
            <p className="mb-4">At Cyber Nexus Tech, we deliver exceptional IT solutions tailored to your business needs. Our team combines expertise and innovation to ensure a seamless integration of technology. Whether it's mobile app development, website design, or desktop applications, we are committed to providing cutting-edge solutions. With a focus on continuous learning, we strive to elevate your business in this ever-evolving digital landscape.</p>
            <Link to="/servicesPage" className="btn btn-primary rounded-pill px-4">Read More</Link>
          </div>
          <div className="col-lg-7">
            <div className="row g-4">
              {/* Service Item 1 */}
              <div className="col-md-6">
                {/* Android Development */}
                <div className="col-12 wow fadeIn" data-wow-delay="0.1s">
                  <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                    <div className="service-icon btn-square">
                      <i className="fa fa-robot fa-2x"></i>
                    </div>
                    <h5 className="mb-3">Android Development</h5>
                    <p>Welcome to Cyber Nexus Tech, crafting powerful Android apps with cross-platform expertise for a standout presence in today's mobile landscape</p>
                    <a className="btn px-3 mt-auto mx-auto" href="/">Read More</a>
                  </div>
                </div>
                {/* Machine Learning */}
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                    <div className="service-icon btn-square">
                      <i className="fa fa-power-off fa-2x"></i>
                    </div>
                    <h5 className="mb-3">Machine learning</h5>
                    <p>Unleash machine learning possibilities with Cyber Nexus Tech. Elevating insights, our expertise transforms data into intelligent solutions, driving innovation in every prediction.</p>
                    <a className="btn px-3 mt-auto mx-auto" href="/">Read More</a>
                  </div>
                </div>
              </div>
              {/* Service Item 2 */}
              <div className="col-md-6 pt-md-4">
                {/* Education & Science */}
                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                    <div className="service-icon btn-square">
                      <i className="fa fa-graduation-cap fa-2x"></i>
                    </div>
                    <h5 className="mb-3">Education & Science</h5>
                    <p>Explore knowledge and innovation with Cyber Nexus Tech. Beyond crafting Android apps, we drive education and science advancements for a transformative future.</p>
                    <a className="btn px-3 mt-auto mx-auto" href="/">Read More</a>
                  </div>
                </div>
                {/* Web Applications */}
                <div className="col-12 wow fadeIn" data-wow-delay="0.7s">
                  <div className="service-item d-flex flex-column justify-content-center text-center rounded">
                    <div className="service-icon btn-square">
                      <i className="fa fa-brain fa-2x"></i>
                    </div>
                    <h5 className="mb-3">Web Applications</h5>
                    <p>Discover innovation in web app development with Cyber Nexus Tech. Elevate your digital presence through our expertise, creating seamless and impactful online experiences.</p>
                    <a className="btn px-3 mt-auto mx-auto" href="/">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
