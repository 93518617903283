import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './css/style.css';
import './css/Navbar.css'; // Import the new Navbar styles

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className={`container-fluid sticky-top`}>
      <div className="container">
        <nav className={`navbar navbar-expand-lg navbar-dark p-0`} >
            <a href="/" className="d-inline-block mb-3">
              <img
                src="img/logoff.png"
                alt="Logo"
                className="img-fluid"
                style={{
                  maxWidth: '150px',
                  height: 'auto',
                  marginTop:'15px' 
                   
                }}
              />
            </a>
          <button className="navbar-toggler" type="button" aria-label="Toggle navigation" onClick={toggleNav}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <Link to="/" className="nav-item nav-link  ">Home</Link>
              <Link to="/AboutPage" className="nav-item nav-link">About</Link>
              <Link to="/servicesPage" className="nav-item nav-link">Services</Link>
              <Link to="/projectsPage" className="nav-item nav-link">Projects</Link>
              <div className="nav-item dropdown">
                <button className="nav-link dropdown-toggle" onClick={() => setIsNavOpen(!isNavOpen)}>Pages</button>
                <div className={`dropdown-menu bg-light mt-2 ${isNavOpen ? 'show' : ''}`}>
                  <Link to="/FeaturesPage" className="dropdown-item nav-item nav-link">Features</Link>
                  <Link to="/OurTeamPage" className="dropdown-item nav-item nav-link">Our Team</Link>
                  <Link to="/FaqsPage" className="dropdown-item nav-item nav-link">FAQs</Link>
                  <Link to="/TestimonialsPage" className="dropdown-item nav-item nav-link">Testimonial</Link>
                </div>
              </div>
              <Link to="/ContactPage" className="nav-item nav-link">Contact</Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
