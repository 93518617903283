import React, { useEffect, useRef } from 'react';
import './Newsletter.css'; // Import the CSS file for styling

const Newsletter = () => {
  const newsletterRef = useRef();

  useEffect(() => {
    const newsletterElement = newsletterRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const newsletterTop = newsletterElement.offsetTop;

      if (scrollPosition > newsletterTop) {
        newsletterElement.classList.add('fadeIn');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container-fluid bg-light newsletter py-5" ref={newsletterRef}>
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-md-5 ps-lg-0 pt-5 pt-md-0 text-start">
            <img className="img-fluid" src="img/ai6.gif" alt="" />
          </div>
          <div className="col-md-7 py-5 newsletter-text">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Newsletter</div>
            <h1 className="text-black mb-4">Let's subscribe to the newsletter</h1>
            <div className="position-relative w-100 mt-3 mb-2">
              <input className="form-control border rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Enter Your Email" style={{ height: '48px' }} />
              <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary fs-4"></i></button>
            </div>
            <small className="text-black-50">Stay informed with the latest updates and exclusive content!</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
