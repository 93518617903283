import React from 'react';
import NavBar from './NavBar';
import ServicesHero from './ServicesHero';
import ServiceSection from './ServiceSection';
import Testimonial from './TestimonialSection';
import Newsletter from './Newsletter';
import Footer from './Footer';

const ServicesPage = () => {
 
    return (
        <div>
            <NavBar/>  {/* Include the NavBar component */}
            <ServicesHero/>  {/* Include the NavBar component */}  
            <ServiceSection/>  {/* Include the NavBar component */}
            <Testimonial/>  {/* Include the NavBar component */} 
            <Newsletter/>  {/* Include the NavBar component */}
           <Footer/>  {/* Include the NavBar component */}
        </div>
      );
  };
  
  export default ServicesPage;