import React, { useEffect, useState } from 'react';

const Spinner = () => {
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const hideSpinner = () => {
      setShowSpinner(false);
    };

    // Simulate a delay for demonstration purposes (you can remove this in your actual implementation)
    const delay = setTimeout(hideSpinner, 2000);

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {showSpinner && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            width: '100vw',
            height: '100vh',
          }}
        >
          <div className="spinner-grow text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Spinner;
