import React from 'react';
import NavBar from './NavBar';
import FeaturesHero from './FeaturesHero'; // Corrected import path
import FeatureSection from './FeatureSection';
import Newsletter from './Newsletter';
import Footer from './Footer';

const FeaturesPage = () => {
  return (
    <div>

      <NavBar/>  {/* Include the NavBar component */}
      <FeaturesHero /> {/* Include the FeaturesHero component */}
      <FeatureSection/>  {/* Include the NavBar component */}
      <Newsletter/>  {/* Include the NavBar component */}
      <Footer/>  {/* Include the NavBar component */}
    </div>
  );
};

export default FeaturesPage;
