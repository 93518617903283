import React, { useEffect, useRef } from 'react';
import './FeatureSection.css'; // Import the CSS file for styling

const FeatureSection = () => {
  const [clientsCounter, setClientsCounter] = React.useState(0);
  const [projectsCounter, setProjectsCounter] = React.useState(0);

  const sectionRef = useRef();

  useEffect(() => {
    const clientsIntervalId = setInterval(() => {
      // Increment the clients counter
      setClientsCounter((prevCounter) => (prevCounter + 1) % 10000);
    }, 100); // Adjust the interval as needed (in milliseconds)

    const projectsIntervalId = setInterval(() => {
      // Increment the projects counter
      setProjectsCounter((prevCounter) => (prevCounter + 1) % 10000);
    }, 100); // Adjust the interval as needed (in milliseconds)

    // Clean up the intervals when the component is unmounted
    return () => {
      clearInterval(clientsIntervalId);
      clearInterval(projectsIntervalId);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const sectionTop = sectionElement.offsetTop;

      if (scrollPosition > sectionTop) {
        sectionElement.classList.add('fadeIn');
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container-fluid   feature pt-5 feature-section" ref={sectionRef}>
      <div className="container pt-5">
        <div className="row g-5">
          <div className="col-lg-6 align-self-center mb-md-5 pb-md-5 wow fadeIn" data-wow-delay="0.3s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Why Choose Us</div>
            <h1 className="text-black mb-4">We're Best in IT Industry with 5 Years of Experience</h1>
            <p className="text-black mb-4">Welcome to Cyber Nexus Tech, a five-year industry leader. We blend creativity and technology to craft innovative IT solutions. Join us on a journey where every line of code tells a story of progress and excellence.</p>
            <div className="d-flex align-items-center text-black mb-3">
              <div className="btn-sm-square bg-primary text-white rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span style={{color: 'black' }}>Innovative Solutions for Today's Challenges</span>
            </div>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-primary text-white rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span style={{color: 'black' }}>Efficient Time Management Strategies</span>
            </div>
            <div className="d-flex align-items-center text-white mb-3">
              <div className="btn-sm-square bg-primary text-white rounded-circle me-3">
                <i className="fa fa-check"></i>
              </div>
              <span style={{color: 'black' }}>Strategic Planning for Future Success</span>
            </div>
            <div className="row g-4 pt-3">
              <div className="col-sm-6">
                <div className="d-flex rounded p-3" style={{ background: 'rgba(256, 256, 256, 0.1)' }}>
                  <i className="fa fa-users fa-3x text-white"></i>
                  <div className="ms-3">
                    <h2 className="text-white mb-0">{clientsCounter}</h2>
                    <p className="text-white mb-0">Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="d-flex rounded p-3" style={{ background: 'rgba(256, 256, 256, 0.1)' }}>
                  <i className="fa fa-check fa-3x text-white"></i>
                  <div className="ms-3">
                    <h2 className="text-white mb-0">{clientsCounter}</h2>
                    <p className="text-white mb-0">Project Complete</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 align-self-end text-center text-md-end wow fadeIn" data-wow-delay="0.5s">
            <img className="img-fluid" src="img/white.gif" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureSection;
