
import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';  // Make sure to provide the correct path to NavBar.js if it's in a different folder
import ContactPage from './ContactPage'; 
import ProjectsPage from './ProjectsPage'; 
import AboutPage from './AboutPage';
import ServicesPage from './servicesPage'; 
import FeaturesPage from './FeaturesPage';  
import OurTeamPage from './OurTeamPage'; 
import FaqsPage from './FaqsPage';
import TestimonialsPage from './TestimonialsPage'; 
import NotFoundPage from './NotFoundPage'; 
import TempTest from './TempTest';
 
 
 

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} index /> {/* Home Page */} 
        <Route path="/AboutPage" element={<AboutPage/>} /> {/* About Us Page */}
        <Route path="/contactPage" element={<ContactPage/>} /> {/* Contact Us Page */}
        <Route path="/projectsPage" element={<ProjectsPage />} /> {/* Projects Page */}
        <Route path="/ServicesPage" element={<ServicesPage />} /> {/* Projects Page */}
        <Route path="/FeaturesPage" element={<FeaturesPage />} /> {/* Projects Page */} 
        <Route path="/OurTeamPage" element={<OurTeamPage />} /> {/* Projects Page */}   
        <Route path="/ContactPage " element={<ContactPage />} /> {/* Projects Page */}
        <Route path="/FaqsPage" element={<FaqsPage />} /> 
        <Route path="/TestimonialsPage" element={<TestimonialsPage />} />
        <Route path="/NotFoundPage" element={< NotFoundPage />} /> {/* Projects Page */} 
        
      </Routes>
    </Router>
  );
};

export default App;
