import React, { useEffect, useRef } from 'react';
import './AboutSection.css'; // Import the CSS file for styling

const AboutSection = () => {
  const sectionRef = useRef();

  useEffect(() => {
    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const sectionTop = sectionElement.offsetTop;

      if (scrollPosition > sectionTop) {
        sectionElement.classList.add('fadeIn');
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div  className="container-fluid py-5  about-section" ref={sectionRef}>
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="about-img">
              <img className="img-fluid" src="img/ai8.gif" alt="About Us" />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">About Us</div>
            <h1 className="mb-4">We Make Your Business Smarter with Our Tech Services</h1>
            <p className="mb-4">
               At Cyber Nexus Tech, we specialize in mobile apps, websites, and applications. Beyond tech, we offer free education for continuous learning. Experience innovation tailored to your business needs. 
            </p>
            <p className="mb-4">
              Our tailored solutions ensure innovative digital experiences for your business. Explore our commitment to free education, fostering continuous learning and growth.
            </p>
            <div className="row g-3">
              <div className="col-sm-6">
                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Award Winning</h6>
                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Professional Staff</h6>
              </div>
              <div className="col-sm-6">
                <h6 className="mb-3"><i className="fa fa-check text-primary me-2"></i>24/7 Support</h6>
                <h6 className="mb-0"><i className="fa fa-check text-primary me-2"></i>Fair Prices</h6>
              </div>
            </div>
            <div className="d-flex align-items-center mt-4">
              <a className="btn btn-primary rounded-pill px-4 me-3" href="/">Read More</a>
              <a className="btn btn-outline-primary btn-square me-3" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-primary btn-square me-3" href="https://www.youtube.com/@CyberNexusTexh-ff4yr" target="_blank"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-primary btn-square me-3" href="https://www.youtube.com/@CyberNexusTexh-ff4yr" target="_blank"><i className="fab fa-instagram"></i></a>
              <a className="btn btn-outline-primary btn-square" href="https://www.youtube.com/@CyberNexusTexh-ff4yr" target="_blank"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
