import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './HeroSection.css'; // Import the CSS file for styling

const HeroSection = () => {
  const heroRef = useRef();

  useEffect(() => {
    const heroElement = heroRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const heroTop = heroElement.offsetTop;

      if (scrollPosition > heroTop) {
        heroElement.classList.add('fadeIn');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container-fluid pt-5  hero-header mb-5" ref={heroRef}>
      <div className="container pt-5">
        <div className="row g-5 pt-5">
          <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
            <div  className="btn btn-sm border rounded-pill   px-3 mb-3 text-primary" >C.N.T</div>
            <h1 className="display-4 text-black mb-4 animated slideInRight">Empowering Your Future</h1>
            <p  className="text-black mb-4 animated slideInRight">At Cyber Nexus Tech, we shape the digital future. We're your partners in web development, free education, and software solutions. Embrace innovation with us.</p>
            <Link to="/AboutPage" className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight">Read More</Link>
            <Link to="/ContactPage"   className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight btn btn-primary rounded-pill px-4 me-3">Contact Us</Link>
          </div>
          <div className="col-lg-6 align-self-end text-center text-lg-end">
            <img className="img-fluid" src=" img/webdev.gif" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
