import React, { useState, useEffect, useRef } from 'react';
import './FAQSection.css'; // Import the CSS file for styling

const FAQSection = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const sectionRef = useRef();

  useEffect(() => {
    const sectionElement = sectionRef.current;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const sectionTop = sectionElement.offsetTop;

      if (scrollPosition > sectionTop) {
        sectionElement.classList.add('fadeIn');
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleAccordionClick = (index) => {
    setTimeout(() => {
      setActiveAccordion(index === activeAccordion ? null : index);
    }, 300); // Set the delay time to 300 milliseconds for a smooth transition
  };

  const faqData = [
    {
      question: "What distinguishes Cyber Nexus Tech in mobile app development?",
      answer: "At Cyber Nexus Tech, our expertise extends beyond conventional boundaries. We specialize in crafting powerful Android apps with cross-platform proficiency, ensuring your mobile presence stands out in today's competitive landscape."
    },
    {
        question: "How does Cyber Nexus Tech ensure a standout presence in the digital arena?",
        answer: "With a solid 5-year background in the IT industry, Cyber Nexus Tech excels in delivering innovative solutions. Our commitment to excellence and cutting-edge services ensures that we not only keep up with industry standards but set new benchmarks."
    },
    {
        question: "What technologies does Cyber Nexus Tech leverage for app development?",
        answer: "In app development, Cyber Nexus Tech harnesses the power of leading technologies. From HTML and CSS to advanced frameworks like React and Angular, our team ensures your Android apps are not only user-friendly but also technologically advanced and flexible."
    },
    {
        question: "Can Cyber Nexus Tech assist with website development?",
        answer: "Absolutely! Cyber Nexus Tech's expertise extends to website development. Whether you're starting from scratch or looking to enhance an existing site, our team can guide you through the process, utilizing programming languages like HTML, CSS, and JavaScript."
    },
    {
        question: "What industries benefit most from Cyber Nexus Tech's services?",
        answer: "Cyber Nexus Tech caters to diverse industries, ensuring our solutions are versatile and impactful. From startups to established enterprises, our expertise in Android app development and IT solutions caters to a broad spectrum of clients, providing tailored services for every business need."
    },
    {
        question: "How can I get started with Cyber Nexus Tech for my project?",
        answer: "Embarking on a project with Cyber Nexus Tech is simple. Contact our team, share your requirements, and let us tailor a solution that aligns with your goals. Our collaborative approach ensures your project is not just a development task but a strategic partnership for success."
    }
    // ... (other faqData items)
  ];

  return (
    <div className="container-fluid py-5 FAQ-section" ref={sectionRef}>
      <div className="container py-5">
        <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
          <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">Popular FAQs</div>
          <h1 className="mb-4">Frequently Asked Questions</h1>
        </div>
        <div className="row">
          {faqData.map((faq, index) => (
            <div className="col-lg-6" key={index}>
              <div className="accordion" id={`accordionFAQ${index}`}>
                <div className={`accordion-item wow fadeIn`} data-wow-delay={`${index * 0.3}s`}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className={`accordion-button ${index === activeAccordion ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => handleAccordionClick(index)}
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded={index === activeAccordion}
                      aria-controls={`collapse${index}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className={`accordion-collapse collapse ${index === activeAccordion ? 'show' : ''}`}
                    aria-labelledby={`heading${index}`}
                    data-bs-parent={`#accordionFAQ${index}`}
                  >
                    <div className="accordion-body">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
