import React, { useEffect, useState } from 'react';
import Spinner from './Spinner'; // Import the Spinner component
import NavBar from './NavBar';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import ServiceSection from './ServiceSection';
import FeatureSection from './FeatureSection';
import CaseStudySection from './CaseStudySection';
import FAQSection from './FAQSection';
import TeamSection from './TeamSection';
import Testimonial from './TestimonialSection';
import Newsletter from './Newsletter';
import Footer from './Footer';
 

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay for demonstration purposes
    const delay = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Clear the timeout on component unmount to avoid memory leaks
    return () => clearTimeout(delay);
  }, []);

  return (
    <div>
      {loading ? (
        <Spinner /> // Show the spinner while loading
      ) : (
        // Render the home page content after loading
        <div>
            <NavBar/>  {/* Include the NavBar component */}
            <HeroSection/>  {/* Include the NavBar component */}
            <AboutSection/>  {/* Include the NavBar component */}
            <ServiceSection/>  {/* Include the NavBar component */} 
            <FeatureSection/>  {/* Include the NavBar component */}
            <CaseStudySection/>  {/* Include the NavBar component */}
            <FAQSection/>  {/* Include the NavBar component */}
            <TeamSection/>  {/* Include the NavBar component */}
            <Testimonial/>  {/* Include the NavBar component */}
            <Newsletter/>  {/* Include the NavBar component */}
            <Footer/>  {/* Include the NavBar component */}
        </div>
      )}
     
    </div>
  );
};

export default Home;
