import React from 'react'; 
import OurTeamHero from './OurTeamHero';
import NavBar from './NavBar';
import TeamSection from './TeamSection';
import Newsletter from './Newsletter';
import Footer from './Footer';

const OurTeamPage = () => {
  return (
    <div>
          <NavBar/>  {/* Include the NavBar component */} 
          <OurTeamHero/>  {/* Include the NavBar component */} 
          <TeamSection/>  {/* Include the NavBar component */} 
          <Newsletter/>  {/* Include the NavBar component */}
          <Footer/>  {/* Include the NavBar component */}
    </div>
  );
};

export default OurTeamPage;
