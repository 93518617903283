import React from 'react';
 

const Footer = () => {
  return (
    <div className="container-fluid bg-secondary text-white-50 footer pt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
            <a href="/" className="d-inline-block mb-3">
              <img
                src="img/logo.png"
                alt="Logo"
                className="img-fluid"
                style={{ maxWidth: '150px', height: 'auto' }}
              />
            </a>
            <p className="mb-0">we shape the digital future. We're your partners in web development, free education, and software solutions. Embrace innovation with us.</p>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p><i className="fa fa-map-marker-alt me-3"></i>Riphah International, FSD</p>
            <p><i className="fa fa-phone-alt me-3"></i>0301 3232084</p>
            <p><i className="fa fa-envelope me-3"></i>cybernexustexch@gmail.com</p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-twitter"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-youtube"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-instagram"></i></a>
              <a className="btn btn-outline-light btn-social" href="https://www.youtube.com/@CyberNexusTexh-ff4yr"  target="_blank"><i className="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
            <h5 className="text-white mb-4">Popular Link</h5>
            <a className="btn btn-link" href="/">About Us</a>
            <a className="btn btn-link" href="/">Contact Us</a>
            <a className="btn btn-link" href="/">Privacy Policy</a>
            <a className="btn btn-link" href="/">Terms & Condition</a>
            <a className="btn btn-link" href="/">Career</a>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
            <h5 className="text-white mb-4">Our Services</h5>
            <a className="btn btn-link" href="/">Android Development</a>
            <a className="btn btn-link" href="/">Web Applications</a>
            <a className="btn btn-link" href="/">Machine learning</a>
            <a className="btn btn-link" href="/">Education & Science</a>
            <a className="btn btn-link" href="/">IT solutions</a>
          </div>
        </div>
      </div>
      <div className="container wow fadeIn" data-wow-delay="0.1s">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a className="border-bottom" href="/">cyber nexus texch</a>, All Right Reserved.
              Designed By <a className="border-bottom" href=" ">cyber nexus texch</a>  <a className="border-bottom" href=" "> </a>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <a href="/">Home</a>
                <a href="/">Cookies</a>
                <a href="/">Help</a>
                <a href="/">FAQs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
