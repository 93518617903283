import React from 'react';
import ContactPageHero from './ContactPageHero';
import NavBar from './NavBar';
import ContactForm from './ContactForm';
import Newsletter from './Newsletter';
import Footer from './Footer';

const NotFoundPage = () => {
  return (
    <>
      <NavBar />  
      <ContactPageHero />
      <ContactForm />
      <Newsletter/>
      <Footer/>
    </>
  );
};

export default NotFoundPage;



