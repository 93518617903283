import React from 'react';
import ContactPageHero from './ContactPageHero';
import NavBar from './NavBar';
import ContactForm from './ContactForm';
import Newsletter from './Newsletter';
import Footer from './Footer';
import FaqsHero from './FaqsHero';
import FAQSection from './FAQSection';

const FaqsPage = () => {
  return (
    <>
      <NavBar />  
      <FaqsHero />
      <FAQSection style={{ marginBottom: '0px' }} />
      <Newsletter/>
      <Footer/>
    </>
  );
};

export default FaqsPage;
