import React from 'react';
import NavBar from './NavBar';
import AboutHero from './AboutHero';
import AboutSection from './AboutSection';
import FeatureSection from './FeatureSection';
import TeamSection from './TeamSection';
import Newsletter from './Newsletter';
import Footer from './Footer';
const AboutPage = () => {
  return (
    <div>

        <NavBar/>  {/* Include the NavBar component */}
        < AboutHero/>  {/* Include the NavBar component */}
        <AboutSection/>  {/* Include the NavBar component */}
        <FeatureSection/>  {/* Include the NavBar component */}
        <TeamSection/>  {/* Include the NavBar component */}
        <Newsletter/>  {/* Include the NavBar component */}
        <Footer/>  {/* Include the NavBar component */}
    </div>
  );
};

export default AboutPage;
