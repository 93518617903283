import React from 'react';
import ContactPageHero from './ContactPageHero';
import NavBar from './NavBar';
import ContactForm from './ContactForm';
import Newsletter from './Newsletter';
import Footer from './Footer';
import TestimonialHero from './TestimonialHero';
import TeamSection from './TeamSection';
import Testimonial from './TestimonialSection';

const TestimonialsPage = () => {
  return (
    <>
      <NavBar />  
      <TestimonialHero />
      <Testimonial />
      <Footer/>
    </>
  );
};

export default TestimonialsPage;
